import { AppContent } from '../AppContent';

const cookiePolicy: AppContent['cookiePolicy'] = {
  CookieHeading: 'Cookie Policy',
  CookieLastUpdated: 'Last updated on May 2018 ',
  CookieTitle:
    'Our Cookies policy explains how we use cookies and other internet tracking software. It also explains the choices you can make about whether we can put some types of cookies on your PC, tablet or mobile phone.',
  CookieData: `In this policy, where we say 'cookies' it can also mean other software that collects data while you use our websites and mobile apps.`,
  CookieDataCollectTo:
    'Data we collect will be held by Black Horse Ltd, which is part of Lloyds Banking Group. We use this data to:',
  CookieListfraud: 'Protect you from fraud and keep improving security',
  CookieListService:
    'Study how people use our websites and apps and our other services, so we can improve them',
  CookieListProduct:
    'Decide which of our products, services and offers may be relevant for you',
  CookieListMarketing:
    'Tailor the marketing you see on social media, apps and other websites ',
  CookieListShareData:
    'We may share this data with other companies in the Group. We also share some data with outside organisations. Some of them may place their own cookies on your device when you visit our website. You can find out more about how we share data - and who we share it with - in our Privacy Policy.',
  CookieSellData: 'We do not sell data to organisations outside our Group.',
  CookiesWhatIsHeading: 'WHAT ARE COOKIES? ',
  CookiesDefenition:
    'Cookies are small files that are sent to your PC, mobile phone or tablet when you visit a website. They stay on your device and are sent back to the website they came from when you visit it again. Cookies collect data and send it to us while you use the site. These are written into mobile apps and web pages that you go to on your device.',
  CookiesSessionHeading: 'SESSION AND PERSISTENT COOKIES',
  CookiesSessionContent:
    'All cookies are either session cookies or persistent cookies. Session cookies last for the length of your visit to a website. They delete themselves when you close your browser. Persistent cookies stay on your device when you close your browser.',
  CookieAdsAndMarketing:
    'Organisations find them useful to see how people use their websites. They can also be used to tailor the ads and marketing you see on social media, apps and other websites.',
  CookieFirstAndThirdHeading: 'FIRST AND THIRD PARTY COOKIES',
  CookieFirstAndThirdContent:
    'Whether a cookie is first or third party depends on where it comes from.',
  CookieFirstWebsite: 'First party cookies are set by websites you go to.',
  CookieFirstWebsiteContent: `Third party cookies are set by outside organisations. For instance, you may be on a website that has 'Like' and 'Share' buttons for social network sites. If you click one, the social network site will put a 'third party' cookie on your device. That cookie may then collect data about you and send it to them too. You will need to set your privacy choices on their site, to tell them how they can use your data.`,
  CookieFirstWebsiteConclusion:
    'We use our own cookies and others from companies we work with such as social media, search engines, other advertising networks, and our business partners.',
  CookieEssentialHeading: 'ESSENTIAL COOKIES AND COOKIES YOU CAN CHOOSE ',
  CookieEssentialContent: `Different types of cookies do different jobs on our website. Some are needed to make the website work. We need your consent to use others that are not essential. You can change your choices at any time. Just click the 'Cookies' link at the end of any page.`,
  CookieCategoryHeading: 'CATEGORY',
  CookieStrictlyNecessary: 'Strictly necessary',
  CookieWhatTheyDoHeading: 'WHAT THEY DO',
  CookieWhatTheyDoContent:
    'These cookies are needed to run our website, to keep it secure if you are logged on and to obey regulations that apply to us.',
  CookieDetailsSafe:
    'If you are a customer, they help us know who you are so that you can log on and manage your accounts. They also help us keep your details safe and private. ',
  CookieOtherJobs: 'Other important jobs they do are:',
  CookieHelpMove: 'Help you move around the site',
  CookieListTellUs: `Tell us if you've been to it before and which pages you went to`,
  CookieListProblemFix:
    'Tell us how the site is working, so we can find and fix any problems. ',
  CookieMyChoicesHeading: 'MY CHOICES',
  CookieMyChoiceContent: `You can't turn off these cookies`,
  CookieFunctionalTitle: 'Functional',
  CookieRememberTitle: 'These cookies are used for remembering things like:',
  CookieListId: 'Your user ID on the log on page',
  CookieListCountry: 'Your region or country',
  CookieListLanguage: 'Your preferred language ',
  CookieListAccessibility:
    'Accessibility options like large font or high contrast pages.',
  CookieCantTurnOFF: `You can't turn off these cookies`,
  CookiePerformanceTitle: 'Performance',
  CookieStudyTitle:
    'These cookies tell us how you and our other customers user our website. We combine all this data together and study it. This helps us to:',
  CookieImprovePerformance: 'Improve the performance of our services',
  CookieImproveProducts: 'Improve the products we provide.',
  CookieConsentTitle: `We'll ask for your consent to use these cookies`,
  CookieMarketingTitle: 'Marketing',
  CookieDecideTitle:
    'These cookies help us decide which of our products, services and offers may be relevant for you. ',
  CookieUseDataTitle:
    'We may use this data to tailor the marketing and ads you see on our own and other websites and mobile apps, including social media. For instance, you may see our ads on other sites after you have been to our website.',
  CookieOnlineAds:
    'If you turn off marketing cookies, you will still see ads online, but they will not be tailored to things that may interest you.',
  CookiePerformanceAndMarketing: 'PERFORMANCE AND MARKETING COOKIES',
  CookieChoice: `Cookies choices you set in your browser replace any you set on a single website. For instance, if you run an ad blocker on your browser, you won't see ads that are tailored to you, even if you have turned on marketing cookies. `,
  CookieICOWebsite: `Find out how to manage cookies in common browsers (Internet Explorer, Chrome, Firefox and Safari) on the Information Commissioners' Office (ICO) website.`,
  CookieKeepInMindHeading: 'KEEP IN MIND',
  CookiePerfAndMarketContent: `Lloyds Banking Group is changing its websites to make sure we ask for your consent to use these cookies. It will take some time to update all our systems. Until then, you won't be able to make your choices on some websites run by companies within our Group. This means you may still see tailored marketing and ads from us during this time, even if you would prefer not to. We will update this cookies policy as soon as the work is complete.`,
  CookieMultipleTitle:
    '<b>Multiple users</b><br/>If more than one person uses your device, the choices set up by other people will apply to you as well unless you change them. For example, if you share a computer with your family, you may see ads based on sites they have visited as well as sites you have visited.',
  CookieMultipleContent:
    'If more than one person uses your device, the choices set up by other people will apply to you as well unless you change them. For example, if you share a computer with your family, you may see ads based on sites they have visited as well as sites you have visited.',
  CookieMoreThanOneTitle:
    '<b>Cookies with more than one use</b><br/>Some of our cookies collect data for more than one use. We will only use these cookies for their essential purposes unless you have given your consent to any other uses they have.',
  CookieAlreadyOnTitle:
    '<b>Cookies that are already on your device</b></br/>Turning off one or more types of cookies will not delete any that have been downloaded in the past. We may still use data we collected up to that point, but will stop collecting new data.',
  CookieManageTitle:
    '<b>Managing cookies choices in your browser</b><br/> You can turn off or delete cookies in your browser. If you do this, it may affect sites that use similar cookies to us.',
  goBackLink: 'Go back',
};

export default cookiePolicy;
