import React, { ReactElement } from 'react';

import { useContent } from '@interstellar/react-app-content';
import { useSelector } from 'react-redux';

import { TermsAndConditionsContent } from './TermsAndConditions.config';
import {
  StyledBoldedParagraph,
  StyledBoldParagraph,
  StyledBox,
  StyledInnerDiv,
  StyledHeading,
  StyledGoBack,
  StyledBottomHr,
} from './termsAndConditions.styled';
import LinkButton from '../../components/linkButton/LinkButton';
import { useDeviceResizing } from '../../customHooks/useDeviceResizing';
import { RootState } from '../../store';
import { StyledContainer } from '../helpCentre/helpCentre.styled';

export default function TermsAndConditions(): ReactElement {
  const {
    TermsHeading,
    TermsWebsiteHeading,
    TermsShouldRead,
    TremsChangedContent,
    TermsWhoCanUseHeading,
    TermsWhoCanUseContent,
    TermsServiceHeading,
    TermsServiceContent,
    TermsDisclaimerHeading,
    TermsProductDisclaimerContent,
    TermsWebsiteDisclaimerContent,
    TermsTimeDelayDisclaimerContent,
    TermsCopyrightsHeading,
    TermsCopyrightsContent,
    TermsLiabilityHeading,
    TermsLiabilityUKContent,
    TermsLiabilityLossContent,
    TermsLiabilityUpToDateContent,
    TermsLiabilityFailureContent,
    TermsLawAndJurisdictionHeading,
    TermsLawAndJurisdictionContent,
    TermsCopyContent,
    goBackLink,
  } = useContent<TermsAndConditionsContent>();

  const handleOnNavigateBack = () => {
    window.history.back();
  };
  const isDeviceFound = useDeviceResizing();

  const handleOnParagraph = (
    isBold: boolean,
    txt: string,
    marginBottom?: any,
    marginTop?: any,
    isSubheading = false,
  ): ReactElement => (
    <StyledBoldedParagraph
      isSubheading={isSubheading}
      size="s2"
      marginTop={marginTop}
      marginBottom={marginBottom}
      isBold={isBold}
    >
      {txt}
    </StyledBoldedParagraph>
  );
  const handleOnHeading = (txt: string): ReactElement => (
    <StyledBoldParagraph marginBottom="none" marginTop="05">
      {txt}
    </StyledBoldParagraph>
  );
  const { isWss } = useSelector((state: RootState) => state.TogglerReducer);
  return (
    <StyledContainer lg={8} md={8} className={isWss ? 'wssTheme' : 'BHTheme'}>
      <StyledBox marginBottom="07" marginTop="07">
        <StyledInnerDiv
          className={isDeviceFound.mobile ? 'mobileFound' : 'notMobile'}
        >
          <StyledHeading size="s4" marginBottom="07">
            {TermsHeading}
          </StyledHeading>
          {handleOnParagraph(false, TermsWebsiteHeading)}
          {handleOnParagraph(false, TermsShouldRead)}
          {handleOnParagraph(false, TremsChangedContent)}
          {handleOnHeading(TermsWhoCanUseHeading)}
          {handleOnParagraph(false, TermsWhoCanUseContent)}
          {handleOnHeading(TermsServiceHeading)}
          {handleOnParagraph(false, TermsServiceContent)}
          {handleOnHeading(TermsDisclaimerHeading)}
          {handleOnParagraph(false, TermsProductDisclaimerContent)}
          {handleOnParagraph(false, TermsWebsiteDisclaimerContent)}
          {handleOnParagraph(false, TermsTimeDelayDisclaimerContent)}
          {handleOnHeading(TermsCopyrightsHeading)}
          {handleOnParagraph(false, TermsCopyrightsContent)}
          {handleOnHeading(TermsLiabilityHeading)}
          {handleOnParagraph(false, TermsLiabilityUKContent)}
          {handleOnParagraph(false, TermsLiabilityLossContent)}
          {handleOnParagraph(false, TermsLiabilityUpToDateContent)}
          {handleOnParagraph(false, TermsLiabilityFailureContent)}
          {handleOnHeading(TermsLawAndJurisdictionHeading)}
          {handleOnParagraph(false, TermsLawAndJurisdictionContent)}
          {handleOnParagraph(false, TermsCopyContent, 'none', '05', true)}
        </StyledInnerDiv>
        <StyledBottomHr />
        <StyledGoBack>
          <LinkButton
            handleButtonClick={handleOnNavigateBack}
            testId="goBackLink"
          >
            {goBackLink}
          </LinkButton>
        </StyledGoBack>
      </StyledBox>
    </StyledContainer>
  );
}
