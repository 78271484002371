export const dataLayer = (): void => {
  const dataLayerScript = document.getElementById('utagData');

  if (dataLayerScript) {
    document.getElementById('utagData').innerHTML = '';
    dataLayerScript.innerHTML = `const utag_data = {
      CanoncialPath: '${window.location.pathname}',
      PageQueryString: '${window.location.hash}',
      Platform: 'MTA',
      Channel: 'Online',
      JourneyName: '${window.appConfig.JOURNEY_NAME}',
      JourneyStepName: '${window.appConfig.JOURNEY_STEP_NAME}',
      TrustDataLayer: true,
      Presentation: 'Responsive',
      PresentationFamily: 'Web',
      TrackEvents: false,
        '@context': '{ "version": "1.0","owner": "Blackhorse - Transport Analytics"}',
      System: 'SCEP',
      Brand: '${window.appConfig.BRAND_NAME} MTA',
      TagVersion: 'C3.20',
      PageRole: '${window.appConfig.PAGE_ROLE}',
      State: 'Unauth',
      Environment: 'www',
      Division: 'Retail',
      AccountID: '294759302842',
      ConsentCookiePresent: true,
      CookieConsentData: false,
      CookieList: true,
      CookiesEssential: true,
    }`;

    setTimeout(() => {
      window.utag?.view({
        JourneyName: window.appConfig.JOURNEY_NAME,
        JourneyStepName: window.appConfig.JOURNEY_STEP_NAME,
        CanonicalPath: window.location.pathname,
      });
    }, 1500);
  }
};
