import React, { ReactElement } from 'react';

import { useContent } from '@interstellar/react-app-content';
import { useSelector } from 'react-redux';

import { CookiePolicyContent } from './CookiePloicy.config';
import {
  StyledFooterList,
  StyledHelpListItem,
  StyledInnerDiv,
  StyledParagraph,
} from './cookiePolicy.styled';
import LinkButton from '../../components/linkButton/LinkButton';
import { useDeviceResizing } from '../../customHooks/useDeviceResizing';
import { RootState } from '../../store';
import { StyledContainer } from '../helpCentre/helpCentre.styled';
import {
  StyledBoldedParagraph,
  StyledBoldParagraph,
  StyledBox,
  StyledHeading,
  StyledGoBack,
  StyledBottomHr,
} from '../termsAndConditions/termsAndConditions.styled';

export default function CookiePolicy(): ReactElement {
  const {
    CookieHeading,
    CookieLastUpdated,
    CookieTitle,
    CookieData,
    CookieDataCollectTo,
    CookieListfraud,
    CookieListService,
    CookieListProduct,
    CookieListMarketing,
    CookieListShareData,
    CookieSellData,
    CookiesWhatIsHeading,
    CookiesDefenition,
    CookiesSessionHeading,
    CookiesSessionContent,
    CookieAdsAndMarketing,
    CookieFirstAndThirdHeading,
    CookieFirstAndThirdContent,
    CookieFirstWebsite,
    CookieFirstWebsiteContent,
    CookieFirstWebsiteConclusion,
    CookieEssentialHeading,
    CookieEssentialContent,
    CookieCategoryHeading,
    CookieStrictlyNecessary,
    CookieWhatTheyDoHeading,
    CookieWhatTheyDoContent,
    CookieDetailsSafe,
    CookieOtherJobs,
    CookieHelpMove,
    CookieListTellUs,
    CookieListProblemFix,
    CookieMyChoicesHeading,
    CookieMyChoiceContent,
    CookieFunctionalTitle,
    CookieRememberTitle,
    CookieListId,
    CookieListCountry,
    CookieListLanguage,
    CookieListAccessibility,
    CookieCantTurnOFF,
    CookiePerformanceTitle,
    CookieStudyTitle,
    CookieImprovePerformance,
    CookieImproveProducts,
    CookieConsentTitle,
    CookieMarketingTitle,
    CookieDecideTitle,
    CookieUseDataTitle,
    CookieOnlineAds,
    CookiePerformanceAndMarketing,
    CookieChoice,
    CookieICOWebsite,
    CookieKeepInMindHeading,
    CookiePerfAndMarketContent,
    CookieMultipleTitle,
    CookieMoreThanOneTitle,
    CookieAlreadyOnTitle,
    CookieManageTitle,
    goBackLink,
  } = useContent<CookiePolicyContent>();
  const handleOnNavigateBack = () => {
    window.history.back();
  };
  const isDeviceFound = useDeviceResizing();
  const { isWss } = useSelector((state: RootState) => state.TogglerReducer);
  const handleOnListItems = (txt: string): ReactElement => (
    <StyledFooterList marginBottom="none">
      <StyledHelpListItem>{txt}</StyledHelpListItem>
    </StyledFooterList>
  );
  const handleOnParagraph = (
    isBold: boolean,
    txt: string,
    marginBottom?: any,
    marginTop?: any,
    isSubheading = false,
  ): ReactElement => (
    <StyledBoldedParagraph
      isSubheading={isSubheading}
      size="s2"
      marginTop={marginTop}
      marginBottom={marginBottom}
      isBold={isBold}
    >
      {txt}
    </StyledBoldedParagraph>
  );
  const handleOnHeading = (txt: string): ReactElement => (
    <StyledBoldParagraph marginBottom="none" marginTop="05">
      {txt}
    </StyledBoldParagraph>
  );
  const handleOnBoldListItems = (txt: string): ReactElement => (
    <StyledFooterList marginBottom="none">
      <StyledHelpListItem>
        <StyledParagraph
          dangerouslySetInnerHTML={{
            __html: txt,
          }}
        >
          {}
        </StyledParagraph>
      </StyledHelpListItem>
    </StyledFooterList>
  );
  return (
    <StyledContainer lg={8} md={8} className={isWss ? 'wssTheme' : 'BHTheme'}>
      <StyledBox marginBottom="07" marginTop="07">
        <StyledInnerDiv
          className={isDeviceFound.mobile ? 'mobileFound' : 'notMobile'}
        >
          <StyledHeading size="s4" marginBottom="07">
            {CookieHeading}
          </StyledHeading>
          {handleOnParagraph(true, CookieLastUpdated)}
          {handleOnParagraph(false, CookieTitle)}
          {handleOnParagraph(false, CookieData)}
          {handleOnParagraph(false, CookieDataCollectTo, 'none')}
          {handleOnListItems(CookieListfraud)}
          {handleOnListItems(CookieListService)}
          {handleOnListItems(CookieListProduct)}
          {handleOnListItems(CookieListMarketing)}
          {handleOnParagraph(false, CookieListShareData, '05', '07')}
          {handleOnParagraph(false, CookieSellData, 'none', '05')}
          {handleOnHeading(CookiesWhatIsHeading)}
          {handleOnParagraph(false, CookiesDefenition)}
          {handleOnHeading(CookiesSessionHeading)}
          {handleOnParagraph(false, CookiesSessionContent)}
          {handleOnParagraph(false, CookieAdsAndMarketing)}
          {handleOnHeading(CookieFirstAndThirdHeading)}
          {handleOnParagraph(false, CookieFirstAndThirdContent)}
          {handleOnParagraph(false, CookieFirstWebsite)}
          {handleOnParagraph(false, CookieFirstWebsiteContent)}
          {handleOnParagraph(false, CookieFirstWebsiteConclusion)}
          {handleOnHeading(CookieEssentialHeading)}
          {handleOnParagraph(false, CookieEssentialContent)}
          {handleOnHeading(CookieCategoryHeading)}
          {handleOnParagraph(false, CookieStrictlyNecessary)}
          {handleOnParagraph(true, CookieWhatTheyDoHeading, 'none', '05')}
          {handleOnParagraph(false, CookieWhatTheyDoContent)}
          {handleOnParagraph(false, CookieDetailsSafe)}
          {handleOnParagraph(false, CookieOtherJobs, 'none')}
          {handleOnParagraph(false, CookieHelpMove, 'none')}
          {handleOnListItems(CookieListTellUs)}
          {handleOnListItems(CookieListProblemFix)}
          {handleOnParagraph(true, CookieMyChoicesHeading, 'none', '07')}
          {handleOnParagraph(false, CookieMyChoiceContent)}
          {handleOnParagraph(true, CookieCategoryHeading, 'none', '05')}
          {handleOnParagraph(false, CookieFunctionalTitle)}
          {handleOnParagraph(true, CookieWhatTheyDoHeading, 'none', '05')}
          {handleOnParagraph(false, CookieRememberTitle, 'none')}
          {handleOnListItems(CookieListId)}
          {handleOnListItems(CookieListCountry)}
          {handleOnListItems(CookieListLanguage)}
          {handleOnListItems(CookieListAccessibility)}
          {handleOnParagraph(true, CookieMyChoicesHeading, 'none', '07')}
          {handleOnParagraph(false, CookieCantTurnOFF)}
          {handleOnParagraph(true, CookieCategoryHeading, 'none', '05')}
          {handleOnParagraph(false, CookiePerformanceTitle)}
          {handleOnParagraph(true, CookieWhatTheyDoHeading, 'none', '05')}
          {handleOnParagraph(false, CookieStudyTitle, 'none')}
          {handleOnListItems(CookieImprovePerformance)}
          {handleOnListItems(CookieImproveProducts)}
          {handleOnParagraph(true, CookieMyChoicesHeading, 'none', '07')}
          {handleOnParagraph(false, CookieConsentTitle)}
          {handleOnParagraph(true, CookieCategoryHeading, 'none', '05')}
          {handleOnParagraph(false, CookieMarketingTitle)}
          {handleOnParagraph(true, CookieWhatTheyDoHeading, 'none', '05')}
          {handleOnParagraph(false, CookieDecideTitle)}
          {handleOnParagraph(false, CookieUseDataTitle)}
          {handleOnParagraph(false, CookieOnlineAds)}
          {handleOnHeading(CookieMyChoicesHeading)}
          {handleOnParagraph(false, CookieConsentTitle)}
          {handleOnHeading(CookiePerformanceAndMarketing)}
          {handleOnParagraph(false, CookiePerfAndMarketContent)}
          <StyledBoldParagraph marginBottom="05" marginTop="05">
            {CookieKeepInMindHeading}
          </StyledBoldParagraph>
          {handleOnBoldListItems(CookieMultipleTitle)}
          {handleOnBoldListItems(CookieMoreThanOneTitle)}
          {handleOnBoldListItems(CookieAlreadyOnTitle)}
          {handleOnBoldListItems(CookieManageTitle)}
          {handleOnParagraph(false, CookieChoice, '05', 'none', false)}
          {handleOnParagraph(false, CookieICOWebsite)}
        </StyledInnerDiv>
        <StyledBottomHr />
        <StyledGoBack>
          <LinkButton
            handleButtonClick={handleOnNavigateBack}
            testId="goBackLink"
          >
            {goBackLink}
          </LinkButton>
        </StyledGoBack>
      </StyledBox>
    </StyledContainer>
  );
}
