import { AppContent } from '../AppContent';

const termsAndConditions: AppContent['termsAndConditions'] = {
  TermsHeading: 'Terms and Conditions',
  TermsWebsiteHeading:
    'THIS WEBSITE IS AN OFFICIAL WEBSITE OF BLACK HORSE LIMITED ("WE" OR "US").',
  TermsShouldRead:
    'YOU SHOULD READ THESE TERMS AND CONDITIONS ("TERMS") CAREFULLY BEFORE USING THIS WEBSITE. USING THE WEBSITE INDICATES THAT YOU ACCEPT THESE TERMS. IF YOU DO NOT ACCEPT THESE TERMS, DO NOT USE THE WEBSITE.',
  TremsChangedContent:
    'Please note these Terms changed on 26 April 2014. We may revise these Terms at any time and you should periodically check the website to review the then current Terms because they are binding on you. Certain provisions of these Terms may be superseded by expressly designated legal notices or terms located on particular pages of the website.',
  TermsWhoCanUseHeading: 'Who can use this site',
  TermsWhoCanUseContent:
    'The products and services described on this website are only available to you if resident in and access the site from within the UK (excluding Channel Islands and Isle of Man). Certain areas of the website will only be open to you if you are one of our existing customers.',
  TermsServiceHeading: 'SERVICE ACCESS',
  TermsServiceContent:
    'While the website is normally available 24 hours a day, certain services provided through the website may not be available 24 hours a day, and we shall not be liable if for any reason the website is unavailable at any time. Access to the website may be suspended temporarily and without notice in the case of system failure, maintenance or repair or for reasons beyond our control.',
  TermsDisclaimerHeading: 'DISCLAIMER',
  TermsProductDisclaimerContent:
    'This website is here to give you information about our products and services. It is up to you to decide whether they are suitable for you. You may want to get advice about this from an independent financial advisor.',
  TermsWebsiteDisclaimerContent:
    'The information on the website may not tell you all you need to know, and you can ask us or an independent financial advisor for more details.',
  TermsTimeDelayDisclaimerContent:
    'Though we try and keep the information up to date, there may be a time delay before we can do so. You should not rely solely on the information on this website.You should carefully review the terms of any agreement you sign with us including checking the details about important things like price or interest rate, as its is the terms in that document that form the agreement between you and us.',
  TermsCopyrightsHeading:
    'COPYRIGHTS, TRADE MARKS AND COPYING MATERIALS ON THIS SITE',
  TermsCopyrightsContent:
    'All copyrights for this website and all trade marks, company names, logos and designs and other materials used on it (except where other contributors are acknowledged) is our intellectual property (or that of our affiliate Lloyds Banking Group companies or our licensors). You may use anything on this website only for your personal information and for this purpose you may print, copy, download or temporarily store extracts from this website. You must not alter or misuse any of this material in any way. Any other use (including commercial uses) is prohibited unless you first get our written permission or an appropriate licence. In particular, no part of the website may be reproduced or stored in any other website or included in any public or private electronic retrieval system or service without our prior written permission. If you breach any of these Terms, your permission to use the website automatically terminates and you must immediately destroy any downloaded or printed extracts from the website.',
  TermsLiabilityHeading: 'EXCLUSIONS OF OUR LEGAL LIABILITY',
  TermsLiabilityUKContent:
    'You should only use this website from the UK. The products and services on it are only available to you if you are a UK resident.',
  TermsLiabilityLossContent:
    'You may not always be able to access this website. We are not responsible for any losses if you cannot do so (for example if you cannot obtain a product at a stated price). We are not responsible if the means of communication you use to access the website or to send us information about yourself or to receive information from us does not work properly.',
  TermsLiabilityUpToDateContent:
    'As we explained above, the information on this website may not always be quite up to date or complete. We advise you to check the website carefully before you agree to buy a product or service, and we are not responsible for any losses you suffer if you fail to do so unless what we put on the website was incorrect or misleading (and provided the correct information was not provided to you or made available before you completed your purchase).',
  TermsLiabilityFailureContent:
    'We are not liable for losses or costs caused by abnormal and unforeseeable circumstances outside our reasonable control, which would have been unavoidable despite all efforts to the contrary, for example delays or failures caused by industrial action, problems with another system or network, third party viruses or malware, mechanical breakdown or data-processing failures.',
  TermsLawAndJurisdictionHeading: 'LAW AND JURISDICTION',
  TermsLawAndJurisdictionContent:
    'This site and these Terms are governed by English law. Any disputes arising from this site shall be resolved by the English courts unless you can show you accessed the site from Scotland in which case the Scottish courts may be used and Scottish law may apply.',
  TermsCopyContent:
    'We strongly recommend that you print a copy of these conditions for your reference.',
  goBackLink: 'Go back',
};

export default termsAndConditions;
