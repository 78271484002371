import React, { ReactElement } from 'react';

import { useContent } from '@interstellar/react-app-content';
import { useSelector } from 'react-redux';

import { PrivacyPolicyContent } from './PrivacyPolicy.config';
import {
  StyledLink,
  StyledSubHeading,
  StyledText,
  StyledParagraph,
  StyledInnerDiv,
} from './privacyPolicy.styled';
import LinkButton from '../../components/linkButton/LinkButton';
import { useDeviceResizing } from '../../customHooks/useDeviceResizing';
import dataQaIds from '../../dataModel/dataQaIds';
import { RootState } from '../../store';
import {
  StyledFooterList,
  StyledHelpListItem,
} from '../cookiePolicy/cookiePolicy.styled';
import { StyledContainer } from '../helpCentre/helpCentre.styled';
import {
  StyledBoldedParagraph,
  StyledBox,
  StyledHeading,
  StyledGoBack,
  StyledBottomHr,
} from '../termsAndConditions/termsAndConditions.styled';

export default function PrivacyPolicy(): ReactElement {
  const {
    PrivacyNoticeHeading,
    LastUpdatedHeading,
    content,
    PersonalInformationHeading,
    PersonalInformationContent,
    PrivacyPromiseHeading,
    PromiseSubHeading,
    PromiseTxt,
    PersonalInfoHeading,
    WhoWeAretxt,
    SectionContent,
    SectionTxt,
    SectionSubTxtHeading,
    SectionSubTxt,
    TheControlHeading,
    TheControlTxt,
    TheControlSubTxt,
    PersonalInfoSubTxt,
    PeronsalInfoService,
    PeronsalInfoFindOutTxt,
    ContactingUsHeading,
    PrivacyNoticeTxt,
    AnyQuestionsTxt,
    HearingSpeechImpairmentText,
    BSLTxt,
    MainContactTxt,
    NotSatisfiedTxt,
    LawHeading,
    LegalTxt,
    PrivacyPromiseTxt,
    DataProtectionTxt,
    DataProtectionSubTxt,
    BussinessTxt,
    BussinessSubTxt,
    SubstantialSubTxt,
    ReasonToRely,
    ServingAsCustomer,
    UsePersonalInfoTxt,
    UsePersonalInfoSubTxt,
    OurReasonHeading,
    OurReasonSubTxt,
    OurLegitimateInterestsHeading,
    OurLegitimateIntrest,
    BusinessImprovementHeading,
    BusinessImprovementTxt,
    OurReasonContracts,
    OurLegitimateProdServices,
    ManagingOperations,
    UsePeronsalInfoFindOutTxt,
    OurLegitimateEfficientTxt,
    ManagingSecurity,
    DetectTxt,
    LegitimateTxt,
    BussinessMangement,
    BussinessMgmtSubtext,
    LegitimateInterestTxt,
    ProcessingSpecialTxt,
    SubstantialTxt,
    CriminalRecordTxt,
    GroupsOfPersonalInfo,
    GroupsPersonalTxt,
    GroupsSubTxt,
    PersonalInfoType,
    Consent,
    Description,
    DescriptionSubTxt,
    FinancialTxt,
    MychoiceTxt,
    YourPositionHistory,
    PersonalType,
    ContactHeading,
    ContactTxt,
    SocioDemographic,
    DetailsSubTxt,
    DescriptionSubtext,
    SpecialTypes,
    TypeOfPersonalInfo,
    DataHeading,
    DataTxt,
    DataSubTxt,
    AccountActivity,
    AccountActivitySubText,
    AccServicesTxt,
    AssServicesSubTxt,
    OutsideDataTxt,
    OutsideDataSubTxt,
    KeepYourPersonalInfo,
    InformationTxt,
    InformationSubTxt,
    InfoReasonTxt,
    InfoReasonSubTxt,
    CustomerTxt,
    CustomerSubTxt,
    SubsidenceTxt,
    NotToGiveInfo,
    ChooseInfoTxt,
    ChooseInfoSubTxt,
    ChooseInfoDelayTxt,
    ChooseInfoContractTxt,
    CookiesTxt,
    HowToUseAppEmailTxt,
    HowToUseAppEmailSubTxt,
    Cookies,
    CookiesParagraph,
    EmailTracking,
    EmailTrackingSubTxt,
    CookiePolicy,
    CookiePolicySubTxt,
    TheControlYouHaveTxt,
    ComplainHeading,
    ComplaintDataTxt,
    ComplaintDataSubTxt,
    ComplaintRegulatorTxt,
    PrivInformationCommissionTxt,
    PrivInfoSubTxt,
    PrivWidthDrawTxt,
    PrivWidthDrawSubTxt,
    PrivConsentTxt,
    PrivConsentSubTxt,
    PrivWithdrawConsentSubTxt,
    PrivLetUsKnowTxt,
    PrivLetUsKnowSubTxt,
    PrivRightQuestionTxt,
    PrivCallUs,
    PrivCall,
    PrivCallUsSubTxt,
    PrivSignVideoTxt,
    PrivCopyInfoTxt,
    PrivDigitalTxt,
    PrivDigitalSubTxt,
    PrivDSAR,
    PrivShareData,
    PrivShareDataSubTxt,
    PrivShareRecordedTxt,
    PrivShareRecordedSubTxt,
    PrivYourRights,
    PrivYourRightsSubTxt,
    PrivObjectTxT,
    PrivObjectSubTxT,
    PrivOfficialTxt,
    PrivOfficialSubTxt,
    PrivOfficialPersonalTxt,
    PrivOfficialList,
    PrivRestrictTxt,
    PrivRestrictSubTxt,
    PrivRelayTxt,
    PrivRelaySubTxt,
    PrivRelayPersonalHeading,
    PrivCreditCardTxt,
    PrivLloydsBankingGroup,
    PrivLBGShare,
    PrivAuthorities,
    PrivTxt,
    PrivList,
    PrivCompanyTxt,
    PrivCollectList,
    PrivOpenBankTxt,
    PrivInsurers,
    PrivFraudTxt,
    PrivInsurerTxt,
    PrivReInsurerTxt,
    PrivInsuranceTxt,
    PrivInsurance,
    PrivProdTxt,
    PrivProdList,
    PrivGenralTxt,
    PrivImproveTxt,
    PrivImproveList,
    PrivSoThatTxt,
    PrivMarketingList,
    PrivOutsideCompaniesTxt,
    PrivThisCouldInclude,
    PrivThisCouldIncludeList,
    PrivChangesInfuture,
    PrivChangesList,
    PrivSellData,
    PrivCombineData,
    PrivCombineSubTxt,
    PrivWorkOutMarktingHeading,
    PrivWorkOut,
    PrivAutomatedInfo,
    PrivAutomatedInfoSubTxt,
    PrivAutomatedSubTxt,
    PrivUnbiasedTxt,
    PrivUnbiasedSubTxt,
    PrivReviewTxt,
    PrivPleaseContactUs,
    privCRAHeading,
    privSection,
    privCreditAndIdentity,
    privSearchInfo,
    privExchangeTitle,
    privExchangeInfoList,
    privUsedataHeading,
    privUseDataList,
    privCRABussiness,
    privApplyForSomeOne,
    privRecordsLinked,
    privCRArecords,
    privMoreOnCRA,
    privWhoAreTheyHeading,
    privWhoAreTheyList,
    privLinksToInfo,
    privTransUnionLink,
    privEquifax,
    privExperian,
    privFPAHeading,
    privConfirmIdentity,
    privBecomeCustomer,
    privRFPAHeading,
    agencyAndBodyHeading,
    agencyAndBody,
    preventDetect,
    obeyingTheLaw,
    legitimateIntrestTitle,
    useInformationTitle,
    useInfoContent,
    otherOrgTitle,
    personalInfoHeading,
    PersonalInfoTitle,
    KindsOfPersonalInfo,
    ThirdPartyContent,
    PreventFraudContent,
    AffectTitle,
    DataFPA,
    DataFPAResult,
    DataTransferUKaandEEA,
    FPADescription,
    FPATitle,
    CIFASTitle,
    DataOutsideUkandEEA,
    DataOutsideUkandEEAContent,
    DataOutside,
    DataOfEEA,
    Transfer,
    TransferContent,
    goBackLink,
  } = useContent<PrivacyPolicyContent>();
  const handleOnNavigateBack = () => {
    window.history.back();
  };
  const isDeviceFound = useDeviceResizing();
  const { isWss } = useSelector((state: RootState) => state.TogglerReducer);
  const handleOnLink = (txt: string, link: string): ReactElement => (
    <>
      <StyledLink href={link} target="_blank">
        {txt}
      </StyledLink>
      <br />
    </>
  );

  const handleOnSubText = (text: string): ReactElement => {
    const textContent = text.split('//');
    const id = 0;
    return (
      <>
        {textContent.map((value: string, index) => (
          <React.Fragment key={`SubTxt-${id + index}`}>
            <StyledText>{value}</StyledText>
            <br />
          </React.Fragment>
        ))}
      </>
    );
  };

  const handleOnDetailsSubtext = (text: string): ReactElement => {
    const listContent = text.split('//');
    const id = 0;
    const result = listContent.map((value, index) => {
      if (index % 2 === 0) {
        return (
          <StyledText key={`DetailsTxt-${id + index}`}>{value}</StyledText>
        );
      }
      return (
        <StyledParagraph key={`DetailsSubTxt-${id + index}`} marginBottom="05">
          {value}
        </StyledParagraph>
      );
    });
    return <div>{result}</div>;
  };

  const handleOnHrefLink = (
    text: string,
    marginBottom?: any,
    marginTop?: any,
    dataQaId?: string,
  ): ReactElement => (
    <StyledParagraph
      marginBottom={marginBottom}
      marginTop={marginTop}
      data-qa-id={dataQaId}
      dangerouslySetInnerHTML={{
        __html: text,
      }}
    >
      {}
    </StyledParagraph>
  );

  const handleOnListItems = (text: string): ReactElement => {
    const textContent = text.split('//');
    const id = 0;
    return (
      <>
        {textContent.map((value: string, index) => (
          <StyledFooterList key={`ListItems-${id + index}`} marginBottom="none">
            <StyledHelpListItem>{value}</StyledHelpListItem>
          </StyledFooterList>
        ))}
      </>
    );
  };

  const renderContent = (listContent: string): ReactElement => {
    const textContent = listContent.split('//');
    const id = 0;
    return (
      <>
        {textContent.map((value: string, index) => (
          <StyledParagraph key={`Paragraph-${id + index}`} marginBottom="05">
            {value}
          </StyledParagraph>
        ))}
      </>
    );
  };

  const handleOnheading = (
    isBold: boolean,
    isSubheading: boolean,
    heading: string,
    size: any,
    marginBottom?: any,
    marginTop?: any,
  ): ReactElement => (
    <StyledSubHeading
      isSubheading={isSubheading}
      isBold={isBold}
      size={size}
      marginBottom={marginBottom}
      marginTop={marginTop}
      aria-level={4}
    >
      {heading}
    </StyledSubHeading>
  );
  const handleOnParagraph = (
    isBold: boolean,
    txt: string,
    marginBottom?: any,
    marginTop?: any,
    isSubheading = false,
  ): ReactElement => (
    <StyledBoldedParagraph
      isSubheading={isSubheading}
      size="s2"
      marginTop={marginTop}
      marginBottom={marginBottom}
      isBold={isBold}
      dangerouslySetInnerHTML={{ __html: txt }}
    >
      {}
    </StyledBoldedParagraph>
  );
  return (
    <StyledContainer lg={8} md={8} className={isWss ? 'wssTheme' : 'BHTheme'}>
      <StyledBox marginBottom="07" marginTop="07">
        <StyledInnerDiv
          className={isDeviceFound.mobile ? 'mobileFound' : 'notMobile'}
        >
          <StyledHeading size="s4" marginBottom="07">
            {PrivacyNoticeHeading}
          </StyledHeading>
          {handleOnheading(true, false, LastUpdatedHeading, 's2', '05')}
          {handleOnParagraph(false, content)}
          {handleOnheading(true, false, PersonalInformationHeading, 's2', '05')}
          {handleOnParagraph(false, PersonalInformationContent)}
          {handleOnheading(true, false, PrivacyPromiseHeading, 's2', '05')}
          {handleOnParagraph(false, PromiseSubHeading, '05', 'none')}
          {handleOnListItems(PromiseTxt)}

          {handleOnheading(true, false, PersonalInfoHeading, 's2', '05', '07')}
          {handleOnParagraph(false, WhoWeAretxt)}
          {handleOnParagraph(false, SectionContent)}
          {handleOnSubText(SectionTxt)}
          {handleOnheading(false, false, SectionSubTxtHeading, 's2', 'none')}
          {handleOnSubText(SectionSubTxt)}
          {handleOnheading(false, true, TheControlHeading, 's2', 'none', '05')}
          {handleOnParagraph(false, TheControlTxt)}
          {handleOnSubText(TheControlSubTxt)}
          {handleOnheading(true, true, PersonalInfoHeading, 's2', '05', '05')}
          {handleOnParagraph(false, PersonalInfoSubTxt)}
          {handleOnParagraph(false, PeronsalInfoService)}
          {handleOnParagraph(false, PeronsalInfoFindOutTxt)}
          {handleOnheading(true, true, ContactingUsHeading, 's2', '05', 'none')}
          {handleOnParagraph(false, PrivacyNoticeTxt)}
          {handleOnParagraph(false, AnyQuestionsTxt)}
          {handleOnParagraph(false, HearingSpeechImpairmentText)}
          {handleOnParagraph(false, BSLTxt)}
          {handleOnParagraph(false, MainContactTxt)}
          {handleOnParagraph(false, NotSatisfiedTxt)}
          {handleOnheading(true, true, LawHeading, 's2', '05', 'none')}
          {handleOnParagraph(false, LegalTxt)}
          {handleOnParagraph(false, PrivacyPromiseTxt)}
          {handleOnParagraph(false, DataProtectionTxt, '05')}
          {handleOnListItems(DataProtectionSubTxt)}
          {handleOnParagraph(false, BussinessTxt, '05', '07')}
          {handleOnParagraph(false, BussinessSubTxt, '05')}
          {handleOnListItems(SubstantialSubTxt)}
          {handleOnParagraph(false, ReasonToRely, '05', '07')}
          {handleOnParagraph(false, ServingAsCustomer, '05')}
          {handleOnParagraph(false, UsePersonalInfoTxt, '05', 'none')}
          {handleOnListItems(UsePersonalInfoSubTxt)}
          {handleOnParagraph(false, OurReasonHeading, '05', '07')}
          {handleOnListItems(OurReasonSubTxt)}
          {handleOnParagraph(false, OurLegitimateInterestsHeading, '05', '07')}
          {handleOnListItems(OurLegitimateIntrest)}
          {handleOnParagraph(
            false,
            BusinessImprovementHeading,
            '05',
            '07',
            false,
          )}
          {handleOnParagraph(false, UsePersonalInfoTxt, '05', 'none')}
          {handleOnListItems(BusinessImprovementTxt)}
          {handleOnParagraph(false, OurReasonHeading, '05', '07')}
          {handleOnListItems(OurReasonContracts)}
          {handleOnParagraph(false, OurLegitimateInterestsHeading, '05', '07')}
          {handleOnListItems(OurLegitimateProdServices)}
          {handleOnParagraph(false, ManagingOperations, '05', '07', false)}
          {handleOnParagraph(false, UsePersonalInfoTxt, '05', 'none')}
          {handleOnListItems(UsePeronsalInfoFindOutTxt)}
          {handleOnParagraph(false, OurReasonHeading, '05', '07')}
          {handleOnListItems(OurReasonContracts)}
          {handleOnParagraph(false, OurLegitimateInterestsHeading, '05', '07')}
          {handleOnListItems(OurLegitimateEfficientTxt)}
          {handleOnParagraph(false, ManagingSecurity, '05', '07', false)}
          {handleOnParagraph(false, UsePersonalInfoTxt, '05', 'none')}
          {handleOnListItems(DetectTxt)}
          {handleOnParagraph(false, OurReasonHeading, '05', '07')}
          {handleOnListItems(OurReasonContracts)}
          {handleOnParagraph(false, OurLegitimateInterestsHeading, '05', '07')}
          {handleOnListItems(LegitimateTxt)}
          {handleOnParagraph(false, BussinessMangement, '05', '07', false)}
          {handleOnParagraph(false, UsePersonalInfoTxt, '05', 'none')}
          {handleOnListItems(BussinessMgmtSubtext)}
          {handleOnParagraph(false, OurReasonHeading, '05', '07')}
          {handleOnListItems(OurReasonContracts)}
          {handleOnParagraph(false, OurLegitimateInterestsHeading, '05', '07')}
          {handleOnListItems(LegitimateInterestTxt)}
          {handleOnParagraph(false, ProcessingSpecialTxt, '05', '07', false)}
          {handleOnParagraph(false, UsePersonalInfoTxt, '05', 'none')}
          {handleOnListItems(SubstantialTxt)}
          {handleOnParagraph(false, OurReasonHeading, '05', '07')}
          {handleOnListItems(CriminalRecordTxt)}
          {handleOnParagraph(true, GroupsOfPersonalInfo, '05', '07', false)}
          {handleOnParagraph(false, GroupsPersonalTxt, 'none', 'none')}
          {handleOnParagraph(false, GroupsSubTxt, '05', '05')}
          {handleOnParagraph(false, PersonalInfoType, 'none', 'none')}
          {handleOnParagraph(false, Consent, '05', 'none')}
          {handleOnParagraph(false, Description, 'none', 'none')}
          {handleOnParagraph(false, DescriptionSubTxt, '05', 'none')}
          {handleOnSubText(FinancialTxt)}
          {handleOnParagraph(false, MychoiceTxt, 'none', '05')}
          {handleOnParagraph(false, YourPositionHistory)}
          {handleOnParagraph(false, PersonalType, 'none', 'none')}
          {handleOnParagraph(false, ContactHeading, 'none', 'none')}
          {handleOnParagraph(false, Description, 'none', '05')}
          {handleOnParagraph(false, ContactTxt, '05', 'none')}
          {handleOnParagraph(false, PersonalInfoType, 'none', 'none')}
          {handleOnParagraph(false, SocioDemographic, '05', 'none')}
          {handleOnDetailsSubtext(DetailsSubTxt)}
          {handleOnListItems(DescriptionSubtext)}
          {handleOnParagraph(false, SpecialTypes, '05', '07')}
          {handleOnDetailsSubtext(TypeOfPersonalInfo)}
          {handleOnParagraph(false, DataHeading, '05', '05')}
          {handleOnParagraph(false, DataTxt, '05', 'none')}
          {handleOnListItems(DataSubTxt)}
          {handleOnParagraph(false, AccountActivity, '05', '07')}
          {handleOnListItems(AccountActivitySubText)}
          {handleOnParagraph(false, AccServicesTxt, 'none', '07')}
          {handleOnParagraph(false, AssServicesSubTxt, 'none', 'none')}
          {handleOnParagraph(false, OutsideDataTxt, '05', '05')}
          {handleOnListItems(OutsideDataSubTxt)}
          {handleOnheading(true, false, KeepYourPersonalInfo, 's2', '05', '07')}
          {handleOnParagraph(false, InformationTxt, '05', '05')}
          {handleOnParagraph(false, InformationSubTxt, '05', '05')}
          {handleOnParagraph(false, InfoReasonTxt, '05', 'none')}
          {handleOnListItems(InfoReasonSubTxt)}
          {handleOnParagraph(false, CustomerTxt, '05', '07')}
          {handleOnParagraph(false, CustomerSubTxt, '05', '05')}
          {handleOnParagraph(false, SubsidenceTxt, '05', '05')}
          {handleOnheading(true, true, NotToGiveInfo, 's2', '05', '05')}
          {handleOnParagraph(false, ChooseInfoTxt)}
          {handleOnParagraph(false, ChooseInfoSubTxt)}
          {handleOnParagraph(false, ChooseInfoDelayTxt)}
          {handleOnParagraph(false, ChooseInfoContractTxt, '05', '05')}
          {handleOnParagraph(true, CookiesTxt, '05', '05', true)}
          {handleOnParagraph(false, HowToUseAppEmailTxt)}
          {handleOnParagraph(false, HowToUseAppEmailSubTxt)}
          {handleOnParagraph(false, Cookies)}
          {handleOnParagraph(false, CookiesParagraph)}
          {handleOnParagraph(false, EmailTracking, '05', '05')}
          {handleOnParagraph(false, EmailTrackingSubTxt)}
          {handleOnParagraph(false, CookiePolicy, 'none', 'none')}
          {handleOnHrefLink(
            CookiePolicySubTxt,
            'none',
            'none',
            dataQaIds.footer.privacyCookieLinks,
          )}
          {handleOnheading(true, true, TheControlYouHaveTxt, 's2', '05', '05')}
          {handleOnheading(true, false, ComplainHeading, 's2', 'none', '05')}
          {handleOnParagraph(false, ComplaintDataTxt, 'none', 'none')}
          {handleOnParagraph(false, ComplaintDataSubTxt, '05', '05')}
          {handleOnParagraph(false, ComplaintRegulatorTxt, '05', '05')}
          {handleOnHrefLink(
            PrivInformationCommissionTxt,
            '05',
            '05',
            dataQaIds.footer.privacyReportLink,
          )}
          {handleOnSubText(PrivInfoSubTxt)}
          {handleOnParagraph(false, PrivWidthDrawTxt, 'none', '05')}
          {handleOnParagraph(false, PrivWidthDrawSubTxt, 'none', 'none')}
          {handleOnParagraph(false, PrivConsentTxt, '05', '05')}
          {handleOnParagraph(false, PrivConsentSubTxt, '05', '05')}
          {handleOnParagraph(false, PrivWithdrawConsentSubTxt, '05', '05')}
          {handleOnParagraph(false, PrivLetUsKnowTxt, 'none', 'none')}
          {handleOnParagraph(false, PrivLetUsKnowSubTxt, 'none', 'none')}
          {handleOnParagraph(false, PrivRightQuestionTxt, '05', '05')}
          {handleOnHrefLink(
            PrivCallUs,
            'none',
            '05',
            dataQaIds.footer.privacyCallLink,
          )}
          {handleOnParagraph(false, PrivCall, 'none', 'none')}
          {handleOnParagraph(false, PrivCallUsSubTxt, '05', '05')}
          {handleOnParagraph(false, PrivSignVideoTxt, '05', '05')}
          {handleOnheading(true, true, PrivCopyInfoTxt, 's2', '05', '05')}
          {handleOnParagraph(false, PrivDigitalTxt, '05', '05')}
          {handleOnParagraph(false, PrivDigitalSubTxt, '05', '05')}
          {handleOnSubText(PrivDSAR)}
          {handleOnParagraph(false, PrivShareData, '05', '05')}
          {handleOnParagraph(false, PrivShareDataSubTxt, '05', '05')}
          {handleOnHrefLink(
            PrivShareRecordedTxt,
            'none',
            'none',
            dataQaIds.footer.privacyRecordLink,
          )}
          {handleOnParagraph(false, PrivCall)}
          {handleOnParagraph(false, PrivShareRecordedSubTxt, '05', '05')}
          {handleOnheading(true, true, PrivYourRights, 's2', '05', '05')}
          {handleOnParagraph(false, PrivYourRightsSubTxt, '05', '05')}
          {handleOnParagraph(false, PrivObjectTxT, '05', '05')}
          {handleOnParagraph(false, PrivObjectSubTxT, '05', '05')}
          {handleOnParagraph(false, PrivOfficialTxt, '05', '05')}
          {handleOnParagraph(false, PrivOfficialSubTxt, '05', '05')}
          {handleOnParagraph(false, PrivOfficialPersonalTxt, '05', 'none')}
          {handleOnListItems(PrivOfficialList)}
          {handleOnParagraph(false, PrivRestrictTxt, '05', '07')}
          {handleOnParagraph(false, PrivRestrictSubTxt, 'none', '05')}
          {handleOnParagraph(false, PrivRelayTxt, 'none', '05')}
          {handleOnParagraph(false, PrivRelaySubTxt, 'none', '05')}
          {handleOnheading(true, true, SectionSubTxtHeading, 's2', '05', '05')}
          {handleOnParagraph(true, PrivRelayPersonalHeading, 'none', '05')}
          {handleOnParagraph(false, PrivCreditCardTxt, 'none', 'none')}
          {handleOnParagraph(false, PrivLloydsBankingGroup, '05', '05')}
          {handleOnParagraph(false, PrivLBGShare, '05', '05')}
          {handleOnParagraph(false, PrivAuthorities, '05', '05')}
          {handleOnParagraph(false, PrivTxt, '05', '05')}
          {handleOnSubText(PrivList)}
          {handleOnParagraph(false, PrivCompanyTxt, '05', '05')}
          {handleOnListItems(PrivCollectList)}
          {handleOnParagraph(false, PrivOpenBankTxt, 'none', '05')}
          {handleOnParagraph(false, PrivInsurers, '05', '05')}
          {handleOnParagraph(false, PrivFraudTxt, '05', '05')}
          {handleOnParagraph(false, PrivInsurerTxt, 'none', '05')}
          {handleOnParagraph(false, PrivReInsurerTxt, 'none', 'none')}
          {handleOnParagraph(false, PrivInsuranceTxt, 'none', 'none')}
          {handleOnParagraph(false, PrivInsurance, 'none', 'none')}
          {handleOnParagraph(false, PrivProdTxt, '05', '05')}
          {handleOnSubText(PrivProdList)}
          {handleOnParagraph(false, PrivGenralTxt, '05', '05')}
          {handleOnParagraph(false, PrivImproveTxt, '05', '05')}
          {handleOnSubText(PrivImproveList)}
          {handleOnParagraph(false, PrivSoThatTxt, '05', '05')}
          {handleOnSubText(PrivMarketingList)}
          {handleOnParagraph(false, PrivOutsideCompaniesTxt, '05', '05')}
          {handleOnParagraph(false, PrivThisCouldInclude, '05', '05')}
          {handleOnSubText(PrivThisCouldIncludeList)}
          {handleOnParagraph(false, PrivChangesInfuture, '05', '05')}
          {handleOnSubText(PrivChangesList)}
          {handleOnParagraph(false, PrivSellData, '05', '05')}
          {handleOnParagraph(false, PrivCombineData, '05', '05')}
          {handleOnParagraph(false, PrivCombineSubTxt, '05', '05')}
          {handleOnheading(
            true,
            true,
            PrivWorkOutMarktingHeading,
            's2',
            '05',
            '05',
          )}
          {renderContent(PrivWorkOut)}
          {handleOnheading(true, true, PrivAutomatedInfo, 's2', '05', '05')}
          {renderContent(PrivAutomatedInfoSubTxt)}
          {handleOnSubText(PrivAutomatedSubTxt)}
          {handleOnParagraph(false, PrivUnbiasedTxt, '05', '05')}
          {handleOnParagraph(false, PrivUnbiasedSubTxt, '05', '05')}
          {handleOnheading(true, true, PrivYourRights, 's2', 'none', '05')}
          {handleOnParagraph(false, PrivReviewTxt, 'none', 'none')}
          {handleOnParagraph(false, PrivPleaseContactUs, 'none', '05')}

          {handleOnParagraph(false, privCRAHeading, 'none', '05', true)}
          {handleOnParagraph(false, privSection)}
          {handleOnParagraph(false, privCreditAndIdentity, '05')}
          {handleOnParagraph(false, privSearchInfo, '05')}
          {handleOnParagraph(false, privExchangeTitle)}
          {handleOnListItems(privExchangeInfoList)}
          {handleOnParagraph(false, privUsedataHeading, '05', '05')}

          {handleOnSubText(privUseDataList)}

          {handleOnParagraph(false, privCRABussiness, '05', '05')}
          {handleOnParagraph(false, privApplyForSomeOne, '05', 'none')}
          {handleOnParagraph(false, privRecordsLinked, '05', 'none')}
          {handleOnParagraph(false, privCRArecords, '05', 'none')}
          {handleOnParagraph(false, privMoreOnCRA, '05', 'none')}

          {handleOnParagraph(false, privWhoAreTheyHeading, 'none', 'none')}
          {handleOnListItems(privWhoAreTheyList)}
          {handleOnParagraph(false, privLinksToInfo, '05', '05')}
          {handleOnLink(
            privTransUnionLink,
            'https://www.transunion.co.uk/legal/privacy-centre?#pc-credit-reference#pc-credit-reference',
          )}
          {handleOnLink(privEquifax, 'https://www.equifax.co.uk/crain')}
          {handleOnLink(privExperian, 'https://www.experian.co.uk/legal/crain')}
          {handleOnParagraph(false, privFPAHeading, '05', '05', true)}
          {handleOnParagraph(false, privConfirmIdentity, '05')}
          {handleOnParagraph(false, privBecomeCustomer, '05')}
          {handleOnParagraph(false, privRFPAHeading, '05')}
          {handleOnParagraph(false, agencyAndBodyHeading)}
          {handleOnListItems(agencyAndBody)}
          {handleOnParagraph(false, preventDetect, '05', '05')}
          {handleOnParagraph(false, obeyingTheLaw, '05', '05')}
          {handleOnParagraph(false, legitimateIntrestTitle)}
          {handleOnParagraph(false, useInformationTitle, '05', '05')}
          {handleOnListItems(useInfoContent)}
          {handleOnParagraph(false, otherOrgTitle, '05', '05')}
          {handleOnParagraph(true, personalInfoHeading, '05', 'none', true)}
          {handleOnParagraph(false, PersonalInfoTitle, '05')}
          {handleOnListItems(KindsOfPersonalInfo)}
          {handleOnParagraph(false, ThirdPartyContent, '05', '05')}
          {handleOnParagraph(false, PreventFraudContent, '05', 'none')}
          {handleOnParagraph(true, AffectTitle, '05', '05', true)}
          {handleOnParagraph(false, DataFPA, '05', '05')}
          {handleOnParagraph(false, DataFPAResult, '05', '05')}
          {handleOnParagraph(false, DataTransferUKaandEEA, 'none', 'none')}
          {handleOnParagraph(false, FPADescription, 'none')}
          {handleOnParagraph(false, FPATitle, '05', '05')}
          {handleOnParagraph(false, CIFASTitle, '05', '05')}
          {handleOnParagraph(false, DataOutsideUkandEEA, 'none', 'none')}
          {handleOnParagraph(false, DataOutsideUkandEEAContent, 'none')}
          {handleOnParagraph(false, DataOutside, '05', '05')}
          {handleOnListItems(DataOfEEA)}
          {handleOnParagraph(false, Transfer, 'none', '05')}
          {handleOnParagraph(false, TransferContent, 'none', 'none')}
        </StyledInnerDiv>
        <StyledBottomHr />
        <StyledGoBack>
          <LinkButton
            handleButtonClick={handleOnNavigateBack}
            testId="goBackLink"
          >
            {goBackLink}
          </LinkButton>
        </StyledGoBack>
      </StyledBox>
    </StyledContainer>
  );
}
